@font-face {
  font-family: "Syne";
  font-weight: 400;
  src: local("Syne"), url(./assets/fonts/Syne-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Syne";
  font-weight: 500;
  src: local("Syne"), url(./assets/fonts/Syne-Medium.woff2) format("woff2");
}
@font-face {
  font-family: "Syne";
  font-weight: 600;
  src: local("Syne"), url(./assets/fonts/Syne-SemiBold.woff2) format("woff2");
}
@font-face {
  font-family: "Syne";
  font-weight: 700;
  src: local("Syne"), url(./assets/fonts/Syne-Bold.woff2) format("woff2");
}

body {
  margin: 0;
  padding-bottom: 5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body.chakra-ui-dark {
  background-color: black;
}

body.chakra-ui-light {
  background-color: #f3f3f3;
}

.home-page-box {
  aspect-ratio: 2/1;
  height: auto;
}

.home-grid {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
}

.menu-box.span-1 {
  aspect-ratio: 1/1;
}

.light-mode-svg > .fill-svg > path {
  fill: #f3f3f3;
}
.light-mode-svg > .stroke-svg > path {
  fill: none;
  stroke: #f3f3f3;
}

.dark-mode-svg > .fill-svg > path {
  fill: #f3f3f3;
}
.dark-mode-svg > .stroke-svg > path {
  fill: none;
  stroke: #f3f3f3;
}

.disabled {
  opacity: 0.6;
}

#myUOMLogo .b {
  fill: #5e5f5f;
}
#myUOMLogo .c {
  fill: #fafcfe;
}
#myUOMLogo .d {
  fill: #224364;
}
#myUOMLogo .e {
  fill: #eaae4e;
}

.back-btn-svg {
  transform: rotate(180deg);
}

.light-mode-svg .back-btn-svg > path {
  stroke: black;
}

.dark-mode-svg .back-btn-svg > path {
  stroke: #f3f3f3;
}

.search-active {
  aspect-ratio: 1/1 !important;
  grid-column-start: span 1 !important;
  grid-column-end: span 1 !important;
}

.markdown-body a {
  color: #224364;
  text-decoration: underline;
}

.markdown-body h1 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  font-weight: 700;
  color: #224364;
}

.markdown-body p {
  margin: 1rem 0;
}

.markdown-body ul,
.markdown-body ol,
.markdown-body li {
  margin-left: 0.75rem;
}

.markdown-body blockquote {
  border-left: 6px solid #224364;
  background-color: #e0ebff;
  padding: 15px;
  margin: 15px 0;
  font-style: italic;
  color: #0044bf;
}

.markdown-body blockquote p {
  margin: 0;
}
